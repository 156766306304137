import AboutSection from '../sections/AboutSection';
import MobileNavbar from './MobileNavbar';

const MobileAbout = () => {


    return  <div className='lg:mx-auto'>
            <AboutSection />
        </div>
}

export default MobileAbout;
