import { createContext, useState } from 'react';

export const modelContext = createContext();

const Provider = (props) => {

    const DEFAULT_STATE = {
        show: false
    }

    const [model, setModel] = useState(DEFAULT_STATE);

    return (
        <modelContext.Provider value={[model, setModel]}>
            {props.children}
        </modelContext.Provider>
    );
};

export default Provider;