import React from 'react';
import MobileLogo from '../../img/mobile_logo.png';

const MobileNavbar = (props) => {

    return (
        <div className='lg:h-38 lg:header lg:mx-auto'>
            <div class="h-20 px-5 flex flex-wrap items-center justify-between lg:h-14 lg:align-middle lg:items-center bg-gray-100 border border-gray-300 shadow">
                <div class="flex align-middle items-center lg:hidden hover:cursor-pointer">
                    {/* <img className='w-60' src={MobileLogo} /> */}
                    <div className='text-black text-2xl font-bold'>{props.title}</div>
                </div>
            </div>
        </div>    
    )
}

export default MobileNavbar;