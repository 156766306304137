import { useContext, useState } from 'react';
import { ZOHO_FORMS } from '../../utils/Constant';
import FOM from '../../img/postings/front_office_manager.png'
import instructor from '../../img/postings/instructor.png'
import { modelContext } from '../../Provider';

const Announcements = () => {
    const announcements = [
            {
                "date": "15 Sep 2023",
                "title": "Join Our Winning Team! We Are Hiring Passionate Taekwondo Instructor / Student Instructors!",
                "caption": "Location: 3695 Kirby Dr, Suite 107, Pearland TX, 77584",
                "id": "instructor",
                "img": instructor
            },
            // {
            //     "date": "15 Sep 2023",
            //     "title": "Join Our Taekwondo Family as Front Office Manager! ",
            //     "caption": "Location: 3695 Kirby Dr, Suite 107, Pearland TX, 77584",
            //     "id": "front_office_manager",
            //     'img': FOM
            // }
    ]

    const [model, setModel] = useContext(modelContext);

    
    return  <div id='announcements' className="mx-auto w-[95%] mt-16">
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
                    <div className=" bg-gray-900 px-8 lg:px-20 py-8 shadow text-gray text-gray-300">
                        <div className="text-2xl lg:text-5xl text-center font-bold">
                            <div>SPECIAL OFFER !</div>
                        </div>
                        <div className="text-xl tracking-wide leading-10 text-left mt-2 lg:mt-0 lg:text-xl lg:leading-10  lg:py-12">
                            <li>FREE TRIAL LESSON</li>
                            <li>$0 - NO REGISTRATION FEE</li>
                            <li>FREE UNIFORM AND BELT</li>
                        </div>
                        <div className="w-4/6 mx-auto">
                            <div 
                                className="text-center tracking-widest border-[1.5px] border-gray-400 text-gray-300 px-10 py-2 ml-2 hover:bg-slate-50 hover:text-black hover:cursor-pointer shadow"
                                onClick={() => window.open(ZOHO_FORMS, '_blank')}> 
                                BEGIN YOUR TRIAL LESSONS
                            </div>
                        </div>
                    </div>
                    <div style={{background: "#f4f3f1"}} class="px-8 lg:px-20 py-8 shadow text-gray overflow-scroll h-full flex flex-col item-center">
                    <div className="mx-auto" >
                        <div className="text-3xl text-center font-bold">
                            <div>JOB OPENINGS</div>
                        </div>
                    </div>
                        {announcements.map(announcement => 
                            <div className="flex my-6">
                                <div className="grow">
                                    <div className="text-sm lg:text-lg text-neutral-500">{announcement.date}</div>
                                    <h5 className="mb-2 text-lg lg:text-2xl font-bold tracking-tight text-gray-900">{announcement.title}</h5>
                                    <div className="text-sm text-neutral-500">{announcement.caption}</div>
                                </div>
                                <div className="flex flex-col justify-center items-center">
                                    <div className="text-lg w-24 bg-black text-white text-center px-1 py-2 rounded-3xl hover:cursor-pointer" onClick={() => setModel({show: !model.show, type: 'img', img: announcement.img})}>
                                        Learn more
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
}

export default Announcements