import React from 'react';
import RateUs from "../img/rate_us.png"

const Contact = () => {

    return (
                <section className="mx-4 lg:my-20 p-4 shadow text-gray-800 rounded-lg">
                    <div class="py-8 lg:py-2 px-4 mx-auto max-w-screen-md grid lg:grid-cols-2 gap-4 text-center h-full">
                        <div>
                            <div className="text-white"> Open Hours</div>
                            <div className="text-gray-400">
                                {/* <div>Sunday: Closed</div> */}
                                <div>Monday:    2:00pm-8:00pm</div>
                                <div>Tuesday:   2:00pm-8:00pm</div>
                                <div>Wednesday: 2:00pm-8:00pm</div>
                                <div>Thursday:  2:00pm-8:00pm</div>
                                <div>Friday:    2:00pm-8:00pm</div>
                                {/* <div>Saturday:  9:00am-12:00pm</div> */}
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <div className="text-white"> Contact Us </div>
                                    <div className="text-gray-400">
                                        <div>3695 Kirby Dr,Suite 107</div>
                                        <div>Pearland TX 77584</div>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <div className="text-white"> Email </div>
                                    <div className="text-gray-400">
                                        <div>info@masterminstkd.com</div>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <div className="text-white"> Telephone  </div>
                                    <div className="text-gray-400">
                                        <div> +1 (832)-493-8940</div>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <div className="text-white"> Follow Us  </div>
                                    <div class='flex items-center justify-center mt-2'>
                                        <a className='mx-2' href= 'https://www.facebook.com/profile.php?id=100093701297033&mibextid=ZbWKwL' target='_blank'>
                                            <img alt="" fetchpriority="high" 
                                            src="https://static.wixstatic.com/media/23fd2a2be53141ed810f4d3dcdcd01fa.png/v1/fill/w_56,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/23fd2a2be53141ed810f4d3dcdcd01fa.png" 
                                            style={{width: "22px", height: "22px", objectFit: "cover"}}
                                            />
                                        </a>
                                        <a className='mx-2' href= 'https://instagram.com/masterminswct?igshid=MzRlODBiNWFlZA==' target='_blank'>
                                            <img alt="" fetchpriority="high" 
                                            src="https://static.wixstatic.com/media/81af6121f84c41a5b4391d7d37fce12a.png/v1/fill/w_56,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/81af6121f84c41a5b4391d7d37fce12a.png" 
                                            style={{width: "22px", height: "22px", objectFit: "cover"}}
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center mt-8">
                                    <img width="120" className="hover:cursor-pointer" onClick={() => window.open("https://goo.gl/maps/veF8ESxJxHj8GCN47", '_blank')} on src={RateUs} />
                                </div>
                            </div> 
                        </div>
                    </div>
                </section>  
    )
}

export default Contact;