import { useContext, useEffect, useState } from "react";
import { modelContext } from "../../Provider";
import { MdDateRange } from 'react-icons/md'
import { Card, CardBody } from "@material-tailwind/react";
import axios from "axios";
import { formatDate } from "../../utils/utilities";

const Events = (props) => {
    
    const [model, setModel] = useContext(modelContext);
    const [events, setEvents] = useState([])

    useEffect(() => {
        console.log(process.env.REACT_APP_API_URL)
        const URL = process.env.REACT_APP_API_URL + "/auth/events"
        axios.get(URL)
            .then((resp) => {
                const data = resp.data
                if(data) {
                    setEvents(data)
                }
            })
            .catch((e) =>  console.log(e))

    }, [])
    

    return <>
            {
                events && events.length === 0 ? 
                    <div id='events' className="mx-auto md:my-32  p-10">
                        <Card className="w-full flex-row">
                                    <CardBody className="w-full text-center m-20 text-xl lg:text-4xl font-bold text-center">
                                        No Upcoming Events
                                    </CardBody>
                        </Card>
                    </div>
                :
                <div id='events' className="mx-auto md:w-[95%] md:my-32  p-10">
                    <div className="text-xl lg:text-4xl font-bold text-center">Upcoming events</div>
                    {events && events.map((e, i) => {
                    return <div className="flex my-6">
                            <Card key={i} className="w-full flex-row">
                                <CardBody className="w-full">
                                    <div className="w-full grid grid-cols-10 gap-1">
                                            <div className="col-span-7 md:col-span-8">
                                                <div className="md:text-xl lg:text-2xl font-bold">{e.eventName}</div>
                                                <div className="py-6 hidden md:block">{e.eventDesc}</div>
                                                {
                                                    e.eventType !== 'INFORMATION' &&
                                                        <div>
                                                            <div className="text-sm md:text-lg md:font-bold flex items-center align-center gap-2">
                                                                <div><MdDateRange /> </div>
                                                                <div>{formatDate(new Date(e.fromDate))}</div>
                                                                {e.toDate && <div>-</div>}
                                                                {e.toDate && <div>{formatDate(new Date(e.toDate))}</div>}
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div className="col-span-3 md:col-span-2  flex justify-center items-center align-center gap-2">
                                                {
                                                    e.asset && <div className="pt-0 flex flex-col justify-center items-center align-center">
                                                    <div className="flex flex-col justify-center items-center"
                                                        onClick={() => setModel({show: !model.show, type: 'img', img: e.asset.filePath})}>
                                                        <div className="text-sm md:text-lg w-24 bg-black text-white text-center px-1 py-2 rounded-3xl hover:cursor-pointer" >
                                                            Learn more
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                })}
                </div>
            }
    </>
   
}

export default Events;