import React from 'react';
import Contact from './Contact'

const Footer = () => {

    return (

    <footer class="rounded-lg shadow bg-gray-900 m-4">
        <div id = 'contactUs' className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
                {/* style={{background: "#f4f3f1"}} */}
                <section  className="bg-slate-50 lg:m-20 p-4 shadow text-gray-800 rounded-lg h-[28rem]">
                    {/* <iframe className={'pdf'} width="100%" height="100%" frameborder="0" src={'https://forms.zohopublic.in/masterminstkd/form/ContactUs/formperma/c9uIUaJiYZjHacP4oBUv_AIciRiSdw8Wf4I0Z4bW_Bw'} /> */}
                    <iframe className={'pdf'} width="100%" height="100%" frameborder="0" src={'zh-contact.htm'} />
                </section>  
                <Contact/>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto :border-gray-700 lg:my-8" />
        <div class="block text-sm text-gray-500 sm:text-center text-gray-400 pb-6">© 2023 <a href="https://.com/" class="hover:underline"></a>. All Rights Reserved.</div>
    </footer>


    )
}

export default Footer;