

const Misc = () => {
        return  <div className="mx-auto w-[90%] lg:w-[95%] id= about">
                <div class="flex items-center mt-4 lg:mt-0 lg:h-[30rem]">
                {/* <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6"> */}
                    <div  className="bg-gray-900 px-8 lg:px-20 py-8 shadow text-gray text-gray-300">
                        <div className="text-2xl lg:text-5xl text-center font-bold">
                            <div>Empower Your Child with Life's Treasures: Discipline, Courage, Dedication and Self-Protection </div>
                        </div>
                        <div className="text-xl tracking-wide leading-10 text-center mt-2 lg:mt-0 lg:text-xl lg:leading-10  lg:py-12">
                        Master Min’s World Champion Taekwondo Conducts Taekwondo classes with the philosophy of helping students become better people through Taekwondo. Taekwondo classes based on that philosophy contain various positive benefits that are needed for growing children. Through various Taekwondo-related activities, students sweat and expend excess energy, learn the spirit of Taekwondo that does not give up while overcoming difficult tasks given to them, and in the process, have fun and develop mentally and physically every day.
                        </div>
                    </div>
                {/* </div> */}
                </div>
        </div>
}

export default Misc