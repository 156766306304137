const ZOHO_FORMS = "https://forms.zohopublic.in/masterminstkd/form/EventRegistration/formperma/dhBMoubhF5XNtmbVCFXB98xYt4MmwvRuCUvUwL8jYuc"
const ZOHO_REGISTRATION = "https://forms.zohopublic.in/masterminstkd/form/RegistrationForm/formperma/UhEUxFvE6MnCimdnmNLpmTyFdGXE3h4ZbyfRH3Q7K-4"

const ABOUTS = [
    "From 1996 to the present day, Master Min has acquired 25 years of experience in Taekwondo and has achieved the 6th Degree Certified KUKKIWON Black Belt.",
    "Having instructed over 2000 students and attained 5 national and international championship victories, Master Min is an esteemed instructor who excels at teaching.",
    "As a former national-level competitor, Master Min holds a Bachelor of Arts Degree in International Tae Kwon Do and a Bachelor of Physical Education from Sangmyung University, Korea.",
    "Master Min is certified in sports massage and sports taping and holds a Physical Education Instructor Level 2 certification from Korea Sports Promotion Agency.",
    "From 2017 to 2022, Master Min served as the Head Master Instructor at Master Lee's World Champion Taekwondo and trained a U.S. TKD National champion and a Gold medalist from 2018 to 2022.",
    "As a Taekwondo main instructor in the Republic of Korea Army from 2011 to 2013, Master Min honed his teaching skills and gained valuable experience in military training."
]


export { ZOHO_FORMS, ZOHO_REGISTRATION, ABOUTS }