import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Card, CardHeader, CardBody, CardFooter, Typography, Button, } from "@material-tailwind/react";
import { FcGoogle } from "react-icons/fc";
import { IoStarSharp } from "react-icons/io5";


const Review = () => {
    const reviews = [
        {
            "name": "Sylvie Ng",
            "desc":  "My 5 year old used to be very shy and get intimidated by other kids, especially the older ones. We enrolled her in Taekwondo hoping it would help her become stronger and learn to defend herself. \
                Master Min is so professional, patient and caring towards his students (kids & adults). He treats the little kids with the same amount of respect as the adult. \
                Master Min and his school helped my child gain confidence in herself, improve her flexibility, coordination and focus. The best part is she learned self-defense, respect, discipline, and made some really good friends in the process."
        }, 
        {
            "name": "Katie trongo",
            "desc":  "Master Min is a fantastic leader and coach, very engaging for the kids! He teaches respect, fun and physical fitness, what a great activity. My daughter loves her class and is really showing progress with her form, flexibility and ability to focus and learn."
        }, 
        {
            "name": "Ronnie Mathew",
            "desc":  "My son and my wife have been coming to Master Min’s Taekwondo classes for several months now and it has been amazing to see their overall growth in knowledge, discipline and enthusiasm towards Taekwondo.\
            We always give this place the highest recommendation to all our friends - I’m sure you will love it !"
        }, 
        {
            "name": "Purva Lad",
            "desc":  "Master Min is a great instructor who is able to balance professionalism and compassion for the kids he teaches based off of this ages. He always remembers every child's name and is very motivating when teaching kids how to improve their skills. Our son has learned to have increased focus and precision as he learns the vital life skill of self defense."
        }, 
        {
            "name": "Brooke Martinez",
            "desc":  "We enrolled my daughter 6 months ago and have not regretted our decision once! Master Min is amazing with his students - kids and adults alike. Each practice we’ve attended, he possesses, patience, encouragement and enthusiasm. Always striving and pushing students to implement their best efforts each practice, with the understanding everyone comes to learn and grow. Each practice my kiddo leaves feeling empowered and proud. It has pushed her to challenge herself and I’ve seen her confidence, patience and determination grow tenfold — it’s wonderful watching your child set goals for themselves, work hard, and reach them! Highly recommend Master Min’s WC TKD. Every practice, she leaves with a smile and confidence, and a yearning to learn more."
        }, 
        {
            "name": "Kabitha Ghimire",
            "desc":  "We signed up our daughters at this place, and they are absolutely obsessed with it! Master Min is incredible at teaching Taekwondo to beginners, and he knows just how to make the classes fun and enjoyable for the kids."
        }, 
        {
            "name": "Alberto Degracia",
            "desc":  "My 8 and my 10 year old have been attending for over a month so far and have truly enjoyed it. They’ve grown so far in self discipline and confidence. Master Min truly shows each student the attention needed and motivates them to get better."
        }, 
        {
            "name": "Joseph Livingston",
            "desc":  "Master Min is an amazing teacher and person. As of today both my son 12 and daughter 6 are enrolled as well as myself 42. Our first meeting with Master Min was with my daughter and I. I was so impressed with how he worked with her and the emotion and attention he provided her with. As for my self I was looking for a way to spend quality time with my children but also get in physical and mental shape. Master Min is working hard to help me achieve both goals. My 12 year  old son who studied for 3 years at another dojo and being out since 2020, has decided to pick up where he left off. He was very impressed with Master Min and took to him very easy. We are all now attending  in individual sessions and family sessions."
        }, 
        {
            "name": "Gordon Olivia",
            "desc":  "Master Min not only teaches well but also has a great sense of humor and treats my daughter kindly, so she looks forward to going to Taekwondo lessons everyday. My daughter, who didn't used to enjoy sports, has also improved her social skills, getting along well with peers. I sincerely recommend this place!!"
        }, 
        {
            "name": "Somaieh Heidari",
            "desc":  "My daughter initially resisted participating in any extracurricular activities, but we were eager to enroll her in a Taekwondo class. With numerous options in the area, we tried a few, but none felt like the right fit, and we were hesitant to place her in a program that wouldn't motivate her. However, when we attended a trial lesson with Master Min, we immediately knew we had found the perfect fit. He is an incredibly intelligent and passionate instructor who communicates effectively with the kids, keeping them engaged in the lessons while teaching them respect, confidence, and technique. Now, my daughter thoroughly enjoys Taekwondo and eagerly anticipates earning her black belt and beyond. The overall experience has been fantastic, with the staff and other instructors being exceptionally friendly, respectful, and welcoming. We extend our gratitude to Master Min for all that he does; we truly appreciate everything."
        }, 
        {
            "name": "Jacqueline Bertrand",
            "desc":  "My son loves going to Master Min’s.  Master Min is a wonderful instructor. His classes are fun and engaging.  Master Min is so great at meeting each child where they are to ensure success no matter the child’s ability level.  I highly recommend these classes!"
        }, 
        {
            "name": "Scoefield McMillan",
            "desc":  "Ever since our son began training with Master Min at his Pearland studio, we've been thrilled with the progress he's made. Master Min's guidance has not only enhanced our son's confidence, strength, and technique but has also instilled valuable life skills. From his days as a 'little tiger' to now proudly wearing a yellow belt in less than a year, the transformation has been incredible to witness. Master Min's dedication to his students' growth is truly commendable, making every session an enriching experience. Highly recommend this dojo for anyone seeking holistic development through Taekwondo."
        }, 
    ]

    const groupedReviews = reviews.reduce((r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r , []);

    return  <div className="mx-auto w-[90%] lg:w-[95%] p-8" style={{background: "#f4f3f1"}}>
                <div className="mx-auto w-[65%] lg:w-[95%]" >
                    <div className="text-3xl text-center font-bold" >
                        <div>TESTIMONIALS</div>
                    </div>
                    <div  className="text-2xl text-center text-slate-500 mt-6" >
                        <div>What others say about </div>
                        <div className="text-4xl text-slate-600"><b>Master Min’s World Champion Tae kwon do!</b></div>
                    </div>
                </div>
                <div>
                    <div className="lg:block hidden" id="swipper-tohide">
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                effect={'fade'}
                                autoplay={{
                                    delay: 10000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[EffectFade, Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                
                            {groupedReviews.map(group => 
                                <SwiperSlide className='' style={{background: "#f4f3f1"}}>
                                    <div className="px-20 grid grid-cols-1 lg:grid-cols-3 lg:gap-6">
                                        {group.map(review => 
                                            <Card className="mt-6">
                                                <CardBody>
                                                    <div className="text-slate-500 flex flex-col justify-center items-center">
                                                        <div className="text-4xl"><FcGoogle /></div>
                                                        <div>From Google</div>
                                                        <div className="flex text-amber-400	"><IoStarSharp/><IoStarSharp/><IoStarSharp/><IoStarSharp/><IoStarSharp/></div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <Typography variant="h4" color="blue-gray" className="mb-2">
                                                            {review.name}
                                                        </Typography>
                                                        <div className="text-slate-500 ">
                                                            {review.desc}
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </div>                        
                                </SwiperSlide>)}
                        </Swiper>
                    </div>

                    <div className="block lg:hidden" id="swipper-tohide">
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                effect={'fade'}
                                autoplay={{
                                    delay: 10000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[EffectFade, Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                
                            {reviews.map(review => 
                                    <SwiperSlide className='' style={{background: "#f4f3f1"}}>
                                        <div className="px-20 h-[40rem] grid grid-cols-1 lg:grid-cols-3 lg:gap-6 overflow-scroll">
                                                <Card className="mt-6">
                                                    <CardBody>
                                                        <div className="text-slate-500 flex flex-col justify-center items-center">
                                                            <div className="text-4xl"><FcGoogle /></div>
                                                            <div>From Google</div>
                                                            <div className="flex text-amber-400	"><IoStarSharp/><IoStarSharp/><IoStarSharp/><IoStarSharp/><IoStarSharp/></div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <Typography variant="h4" color="blue-gray" className="mb-2">
                                                                {review.name}
                                                            </Typography>
                                                            <div className="text-slate-500">
                                                                {review.desc}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                        </div>                        
                                    </SwiperSlide>
                            )}
                        </Swiper>
                    </div>

                </div>
        </div>
}

export default Review