import Pagewrapper from "../component/pagewrapper/Pagewrapper"
import Footer from "./Footer"
import Navbar from "./Navbar"
import { Card, CardHeader, CardBody, CardFooter, Typography, Button, } from "@material-tailwind/react";
import LtHeader from '../img/little_tiger/top.png'
import Lt1 from '../img/little_tiger/1.JPG'
import Lt2 from '../img/little_tiger/2.JPG'
import Lt3 from '../img/little_tiger/3.JPG'

import YHeader from '../img/youth/top.JPG'
import Y1 from '../img/youth/1.JPG'
import Y2 from '../img/youth/2.JPG'
import Y3 from '../img/youth/3.JPG'


import TaHeader from '../img/teen_adult/top.png'
import Ta1 from '../img/teen_adult/1.JPG'
import Ta2 from '../img/teen_adult/2.JPG'
import Ta3 from '../img/teen_adult/3.JPG'

import FmHeader from '../img/family/top.png'
import Fm1 from '../img/family/1.JPG'
import Fm2 from '../img/family/2.JPG'
import Fm3 from '../img/family/3.JPG'


const ProgramPage = (props) =>  {

    const program_content = {
        'little_tiger_program': {
            'heading': 'Little Tiger',
            'headerImg': LtHeader,
            'breadCrumbs': ['Home', 'Little Tiger'],
            'description': 
            <>
                <div className="py-2 tracking-wide">
                    Character, first formed in childhood, has the greatest impact throughout life. In addition, motor nerves, which are
                    made of cells formed at the same time, influence the motor ability of a lifetime.
                    Master Min’s World Champion Taekwondo is ready to start teaching your 4–5-year-old child today! With our fun
                    and high-energy Little Tigers program, your child will run, jump, kick, and punch their way to greater coordination,
                    balance, flexibility and strength. Our experienced, professional instructors also make them develop everyday life
                    skills like concentration, respect, discipline, confidence and listening skills through the art of Taekwondo.
                </div>
                <div className="py-2 tracking-wide">
                    Our rewards through achievement systems also build your child's confidence and prepare them to stand up
                    independently and face the world with confidence.
                </div>
            </>,
            'sectionOne':
                <div className="py-2 tracking-wide">
                        At Master Min's World Champion Taekwondo, our Little Tigers program is tailored to offer a comprehensive experience for 4 to 5-year-old kids,
                        fostering their physical, mental, and social growth. Through entertaining and interactive activities, little ones will enhance their coordination,
                        balance, flexibility, strength, agility, focus, respect, discipline, self-confidence, and listening skills.
                </div>
            ,
            'sectionOneImg': Lt1,
            'sectionTwo': <>
                <ul>
                    {
                        [
                            'Fun activity, Positive energy, Sociability',
                            'Coordination, Balance, Flexibility, Strength, agility',
                            'Concentration, Respect, Discipline, Confidence, Listening ability'
                        ].map(benefit => 
                        <li>
                            - {benefit}
                        </li>
                        )
                    }
                </ul>
            </>,
            'sectionTwoImg': Lt2,
            'sectionThree': <div className="py-2 tracking-wide">
                    Overall, our Little Tigers program at Master Min’s World Champion Taekwondo is
                    designed to provide your child with a well-rounded experience that promotes
                    physical, mental, and social development. Through fun and engaging activities,
                    children will improve their coordination, balance, flexibility, strength, agility,
                    concentration, respect, discipline, confidence, and listening ability.
            </div>,
            'sectionThreeImg': Lt3,
            
        },
        'youth_program': {
            'heading': 'Youth Program',
            'headerImg': YHeader,
            'breadCrumbs': ['Home', 'Youth Program'],
            'description': <>
                <div className="py-2 tracking-wide">
                    As everyone know, from childhood to adolescence physical and mental development and the social skills that 
                    form are very important in determining what kind of person they will grow into in the future. 
                    The Youth Program helps motivate students by setting goals step by step, learns the spirit of Taekwondo that 
                    does not give up while overcoming the difficult tasks given to them, and has fun in the process, they can develop 
                    mentally improving concentration, discipline, perseverance, self-confidence and physically, they become a 
                    person who develops every day with good results of improving overall motor skills such as flexibility, endurance, 
                    strength, coordination, and sense of balance.
                </div>
                <div className="py-2 tracking-wide">
                    Through a well-designed curriculum and effective teaching methods, students can gain the mental and physical benefits of Taekwondo. 
                    These advantages also carry over to their daily lives, enhancing their performance and behavior at school and home.
                </div>
            </>,
            'sectionOneImg': Y1,
            'sectionOne': <div className="py-2 tracking-wide">
                    Our Taekwondo Youth Program offers an entertaining and engaging experience for kids by incorporating interactive games, teamwork exercises, and stimulating challenges to
                    maintain their interest and motivation. 
            </div>,
            'sectionTwo': <>
                <ul>
                    {
                        [
                            'Sociability, positive energy, consideration (respect)',
                            'Bully prevention, Self-defense, flexibility, endurance, strength, coordination, balance',
                            'Concentration, Discipline, perseverance, goal setting, confidence, Self-control'
                        ].map(benefit => 
                        <li>
                            - {benefit}
                        </li>
                        )
                    }
                </ul>
            </>,
            'sectionTwoImg': Y2,
            'sectionThree': <div className="py-2 tracking-wide">
                    Overall, youth programs in Taekwondo provide a fun and engaging way for children
                    and teens to learn a martial art while also developing important physical and life
                    skills. Through regular training and practice, students can improve their technique,
                    confidence, and overall well-being.
            </div>,
            'sectionThreeImg': Y3,
        },
        'teen_adult_program': {
            'heading': 'Teen / Adult Program',
            'headerImg': TaHeader,
            'breadCrumbs': ['Home', 'Teen / Adult Program'],
            'description': <>
                <div className="py-2 tracking-wide">
                    Adult program helps you to stay in great shape and learn practical style of Taekwondo. 
                    The adult program is a good blend of Taekwondo and fitness exercises. It is designed to help you achieve better 
                    flexibility, endurance, strength, cardiorespiratory endurance and mental strength. In addition, you can relieve 
                    stress and have fun with your classmates in an atmosphere that is always positive and supportive. 
                    No matter what your age or fitness level is, Taekwondo provides a full range of mental and physical benefits. To 
                    start, learning TKD doesn’t require any prior experience
                </div>
                <div className="py-2 tracking-wide">
                    You do not have to be physically fit, and we train all ages!
                </div>
            </>,
            'sectionOneImg': Ta1,
            'sectionOne': <div className="py-2 tracking-wide">
                The Taekwondo Adult Program assists students in attaining improved physical fitness, mental focus, and overall well-being. Additionally, it aids in cultivating
                crucial life skills such as discipline, concentration, determination, and self-assurance.
            </div>,
            'sectionTwo': <>
                    <ul>
                            {[
                        'Stay in great shape, lose weight, gain strength',
                        'Flexibility, endurance, muscle strength, cardiorespiratory endurance',
                        'Strong mental power, stress relief, enjoyable exercise'
                    ].map(benefit => 
                        <li>
                            - {benefit}
                        </li>
                        )
                    }
                </ul>
            </>,
            'sectionTwoImg': Ta2,
            'sectionThree': <div className="py-2 tracking-wide">
                    Overall, it's important to note that Taekwondo can provide a wide range of benefits,
                    and choosing the right program for individual needs can help maximize those
                    benefits.
            </div>,
            'sectionThreeImg': Ta3,
        },
        'family_program': {
            'heading': 'Family Program',
            'headerImg': FmHeader,
            'breadCrumbs': ['Home', 'Family Program'],
            'description':  <>
                <div className="py-2 tracking-wide">
                    The Taekwondo Family Program establishes a foundation for families to connect, grow, and evolve collectively. It encourages physical well-being, character building,
                    and joint experiences that reinforce family ties while imparting values that can positively influence family relationships and individual development –
                    tailored specifically for taekwondo schools.
                </div>
                <div className="py-2 tracking-wide">
                    Provide yourself and your children with a good experience and development opportunity that will last a lifetime from our enjoyable Taekwondo classes
                </div>
            </>,
            'sectionOne': <div className="py-2 tracking-wide">
                    Training side by side with your children is a truly special experience.
                    The Family Program is like family members sailing together toward the same goal. By training, sweating and
                    being physically active, not only is it a fun and enjoyable workout, but it also builds a deeper bond with more
                    support, understanding and respect for each other. 
            </div>,
            'sectionOneImg': Fm3,
            'sectionTwo': <>
                <ul>
                    {[
                        'Family bonding experience, Fun activity',
                        'Self defense skill, Whole body workout',
                        'Building consensus among family members'
                    ].map(benefit => 
                    <li>
                        - {benefit}
                    </li>
                    )
                    }
                </ul>
            </>,
            'sectionTwoImg': Fm2,
            'sectionThree': <div className="py-2 tracking-wide">
                    Our experienced instructors provide a safe and supportive environment where family
                    members can work together and challenge themselves while having fun. Taekwondo
                    techniques are taught in a manner that is appropriate for all ages and skill levels,
                    allowing everyone in the family to progress and achieve their individual goals.
            </div>,
            'sectionThreeImg': Fm1,
        }
    }

    console.log(props.page)
    console.log(program_content[props.page]['benefits'])
    return <>
            <Navbar/>
                <Card className="w-10/12 lg:w-10/12 m-auto rounded-xl shadow-lg lg:text-xl lg:leading-[2rem] my-10 desc-font">
                    <CardHeader  shadow={false} floated={false} className="m-0 lg:h-[32rem] 2xl:h-[36rem] min-[1800px]:h-[44rem] rounded-b-none" >
                        <img src={program_content[props.page]['headerImg']} className={'relative 2xl:w-full'} />
                    </CardHeader>
                    
                    <CardBody className="px-10 py-4 text-slate-500">
                        <section className="my-10">
                            <Typography variant="h2" className="mb-2 text-slate-800 text-center">
                                {program_content[props.page]['heading']}
                            </Typography>
                            <div className="lg:text-base">
                                {program_content[props.page]['description']}
                            </div>
                        </section>
                        <section className="my-20">
                                <div className="grid lg:grid-cols-2 gap-10">
                                    <div className="flex justify-center">
                                        <img src={program_content[props.page]['sectionOneImg']} className={'rounded-xl shadow-2xl relative lg:h-[25rem]'} />
                                    </div>
                                    <div className="flex justify-center items-center py-2 lg:px-12">
                                        <div>
                                        <Typography variant="h2" className="my-6 text-slate-800">
                                            Program Overview
                                        </Typography>
                                        <div className="h-1 bg-blue-800 w-16 my-2 lg:my-6 lg:w-24"></div>
                                        <div className="lg:text-base">
                                            {program_content[props.page]['sectionOne']}
                                        </div>
                                        </div>
                                    </div>
                                </div>
                        </section>
                        <section className="my-20">
                                <div className="grid lg:grid-cols-2 gap-10">
                                    <div className="flex justify-center">
                                        <img src={program_content[props.page]['sectionTwoImg']} className={'rounded-xl shadow-2xl relative lg:h-[26rem]'} />
                                    </div>
                                    <div className="flex justify-center items-center py-2 lg:px-12">
                                        <div>
                                        <Typography variant="h2" className="my-6 text-slate-800">
                                            Program Goals
                                        </Typography>
                                        <div className="h-1 bg-blue-800 w-16 my-2 lg:my-6 lg:w-24"></div>
                                        <div className="lg:text-base">
                                            {program_content[props.page]['sectionTwo']}
                                        </div>
                                        </div>
                                    </div>
                                </div>
                        </section>
                        <section className="my-20">
                                <div className="grid lg:grid-cols-2 gap-10">
                                    <div className="flex justify-center">
                                        <img src={program_content[props.page]['sectionThreeImg']} className={'rounded-xl shadow-2xl relative lg:h-[26rem]'} />
                                    </div>
                                    <div className="flex justify-center items-center py-2 lg:px-12">
                                        <div>
                                        <Typography variant="h2" className="my-6 text-slate-800">
                                            Enhancing Your Taekwondo Experience
                                        </Typography>
                                        <div className="h-1 bg-blue-800 w-16 my-2 lg:my-6 lg:w-24"></div>
                                        <div className="lg:text-base">
                                            {program_content[props.page]['sectionThree']}
                                        </div>
                                        </div>
                                    </div>
                                </div>
                        </section>
                    </CardBody>
                </Card>
            <Footer />
            </>
}

export default ProgramPage