import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';


const Faq = () => {
    const faqs = [ 
        {
            "question": "When is a great time to start Taekwondo?",
            "ans": "In our experience and as a former School Teacher we believe that children ages four and up will be able to start and benefit for our Taekwondo lessons. Starting at four, your children are able to participate in our Trial Program, which will allow our instructors to work with them and evaluate their readiness for classes."
        },
        {
            "question": "Can I try a class before signing up?",
            "ans": "Yes, we offer a free trial class to all new students. This is a great opportunity to experience Taekwondo firsthand and meet our instructors and students."
        },
        {
            "question": "Is Taekwondo safe for kids?",
            "ans": "Yes, Taekwondo is a safe and fun activity for children of all ages. Our instructors are trained to teach techniques in a safe and controlled environment, and we also provide safety equipment such as padded mats and protective gear."
        },
        {
            "question": "How often should I attend Taekwondo classes?",
            "ans": "We recommend attending classes at least twice a week to see progress and improvement in your skills. However, we understand that schedules can be busy, so we offer flexible class schedules to accommodate our students needs."
        },
        {
            "question": "Can I practice Taekwondo if I have an injury or physical limitation?",
            "ans": "Yes, we can work with you to modify techniques and exercises to accommodate your injury or physical limitation. Our instructors are trained to adapt to individual needs and provide a safe and comfortable environment for all students."
        }
    ]
    return (
        <div className='header mx-auto mb-24'>
            <div className="pt-12 text-3xl text-center font-bold mb-8">
                <div>FAQ'S</div>
            </div>
            <Accordion>
                {faqs.map(faq => <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {faq.question}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        {faq.ans}
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>)}
            </Accordion>
        </div>
    )
}

export default Faq