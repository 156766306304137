
const paddingNumber = (value) => {
    return value < 10 ? "0" + value : value
}

const formatDate = (value) => {
    const date = new Date(value)
    return [ paddingNumber(date.getMonth()+1), paddingNumber(date.getDate()), date.getFullYear()].join('/')
}

export { formatDate }