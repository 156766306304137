import Navbar from './Navbar';
import Footer from './Footer';
import AboutSection from './sections/AboutSection';
import Pagewrapper from '../component/pagewrapper/Pagewrapper';

const About = () => {


    return  <div className='lg:mx-auto'>
            <Navbar/>
            <Pagewrapper heading={'About'}>
                <AboutSection />
            </Pagewrapper>
            <Footer />
        </div>
}

export default About;
