import React, { useContext } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import home1 from '../img/video/home1.mp4';
import home2 from '../img/video/home2.mp4';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../css/caption.css'
import { ZOHO_FORMS } from '../utils/Constant';
import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';
import { modelContext } from '../Provider';

const Carousel = () => {


    const [model, setModel] = useContext(modelContext);

    const  carouselContents = [
        {
            "type": "video",
            "url": home2,
            "header": "MASTER MIN'S TAEKWONDO",
            "caption": "Join Master Min's World Champion Taekwondo Team and Unleash Your Inner Champion!"
        },
        {
            "type": "video",
            "url": home1,
            "header": "MASTER MIN'S TAEKWONDO",
            "caption": "Join Master Min's World Champion Taekwondo Team and Unleash Your Inner Champion!"
        }
    ]

    return (
        <div  className='h-96 lg:h-[38rem] lg:w-[95%] lg:mx-auto'>
            <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    effect={'fade'}
                    autoplay={{
                    delay: 60000,
                    disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[EffectFade, Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                {carouselContents.map(content => 
                            <SwiperSlide className='h-96 lg:h-[38rem]'>
                                    {
                                        content.type === 'video' ?
                                            <video className={'lg:h-[54rem] object-fill w-full h-full'} autoPlay loop muted>
                                                    <source src={content.url} type='video/mp4' />
                                            </video> 
                                            :
                                            <img src={content.url} className={'lg:h-[50rem] object-fill w-full h-full'} />
                                    }
                                    <div style={{backgroundColor: "#00000070"}} class="h-[38rem] absolute w-full z-50">
                                        <div className="h-full m-auto text-white w-9/12 flex justify-end items-center">
                                            <div className='lg:w-5/12 text-left' data-aos="fade-up" data-aos-delay="3000" data-aos-duration="1000">
                                                <div className="text-2xl  text-slate-100  lg:text-5xl lg:leading-[3.5rem]">
                                                    <div>{content.header}</div>
                                                </div>
                                                
                                                <div className="h-2 bg-blue-800 w-16 my-2 lg:my-6 lg:w-24"></div>

                                                <div className="lg:text-3xl text-gray-300 lg:text-left">
                                                    <div>{content.caption}</div>
                                                </div>
                                                <div className="my-6 lg:hidden block ">
                                                    <a href="https://app.masterminstkd.com/signup" target='_blank' className="w-32 bg-blue-800 border-2 border-blue-800 text-white flex justify-center align-center py-2 hover:cursor-pointer">                                                    
                                                        REGISTER 
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{backgroundColor: "#00000099"}} className="hidden lg:block absolute bottom-0 w-1/3 h-32 text-white">
                                            <div className="text-white flex justify-center text-xl items-center h-full">
                                                <a className="bg-blue-800 border-2 border-blue-800 text-white px-10 py-2 mr-2 hover:cursor-pointer"
                                                onClick={() => window.open(ZOHO_FORMS, '_blank')} 
                                                > BOOK A TRIAL </a>
                                                <a href="https://app.masterminstkd.com/signup" target='_blank'
                                                    className="border-2 border-slate-300 text-slate-300 px-10 py-2 ml-2 hover:bg-slate-300 hover:text-black hover:cursor-pointer"> 
                                                        REGISTER
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                            </SwiperSlide>)
                }
            </Swiper>
        </div>)
}

export default Carousel;