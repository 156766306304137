import Cards from "../../component/cards/Cards"
import CardsV2 from "../../component/cards_v2/CardsV2"
import Adult from "../../img/teen_adult/main_page.JPG"
import LittleTiger from "../../img/little_tiger/main_page.JPG"
import Youth from "../../img/youth/main_page.JPG"
import Family from "../../img/family/main_page.JPG"

   
const Programs = () => {
    const programs = [
        {
            "img": LittleTiger,
            "header": "Little Tiger",
            "description": "Little Tigers program is tailored to offer a comprehensive experience for 4 to 5-year-old kids, fostering their physical, mental, and social growth ,through entertaining and interactive activities.",
            "page": "/little-tiger-program"
        },
        {
            "img": Youth,
            "header": "Youth Program",
            "description": "The Youth Program helps motivate students by setting goals step by step, learns the spirit of Taekwondo that does not give up while overcoming the difficult tasks given to them.",
            "page": "/youth-program"
        },
        {
            "img": Adult,
            "header": "Teen/Adult Program",
            "description": "The adult program is a good blend of Taekwondo and fitness exercises. It is designed to help you achieve better flexibility, endurance, strength, cardiorespiratory endurance and mental strength.",
            "page": "/teen-adult-program"
        },
        {
            "img": Family,
            "header": "Family Program",
            "description": "The Family Program fosters a shared journey towards a common goal. It combines training, physical activity, and enjoyment, strengthening the familial bond.",
            "page": "/family-program"
        }
    ]
    return (
        <div className="flex items-center mt-4 lg:mt-0 lg:h-[38rem]">
            <div className="mx-auto w-[75%] lg:w-[95%]" >
                <div className="text-3xl text-center font-bold" id='ourPrograms'>
                    <div>OUR PROGRAMS</div>
                </div>
                <div class="mt-8 lg:h-96 grid grid-cols-1 lg:grid-cols-4 lg:gap-6 ">
                    {programs.map(program => <CardsV2 
                        image={program.img}
                        header={program.header} 
                        description={program.description}
                        link={program.page}
                        />)}
                </div>
            </div>
        </div>
    )
}

export default Programs;