import { useContext, useEffect, useState } from "react";
// import ClassSchedule from "../img/class_schedule.jpeg"
import MobileNavbar from "../mobile/MobileNavbar";
import axios from "axios";
import { Card, Button } from 'flowbite-react';
import { modelContext } from "../../Provider";


const Schedule = () => {

    const [scheduleImageUrl, setScheduleImageUrl] = useState(null)
    const [model, setModel] = useContext(modelContext);

    useEffect(() => {
        console.log(process.env.REACT_APP_API_URL)
        const URL = process.env.REACT_APP_API_URL + "/auth/schedule"
        axios.get(URL)
            .then((resp) => {
                console.log(resp)
                const data = resp.data
                if(data && data.filePath) {
                    setScheduleImageUrl(data.filePath)
                }
            })
            .catch((e) =>  console.log(e))
        // console.log(resp)

    }, [])

    return <>
        <div className="header lg:mx-auto" >
                <div className="mx-auto w-[80%] mt-6">
                    {scheduleImageUrl ?
                            <Card className="w-full text-center" 
                                onClick={() => setModel({show: !model.show, type: 'img', img: `${scheduleImageUrl}?${new Date().getTime()}`})}>
                                <img className="w-3/4 m-auto" src={`${scheduleImageUrl}?${new Date().getTime()}`} />
                                <Button color="light" className="text-blue-700">View Details</Button>
                            </Card>
                            : 
                            <Card className="w-full p-10 text-center border-0"> No Schedules found</Card>
                    }
                </div>
        </div>
        </>
}

export default Schedule;