import Pagewrapper from "../../component/pagewrapper/Pagewrapper"
import Events from "../sections/Events"
import Programs from "../Contact"
import MobileNavbar from "./MobileNavbar"
import Contact from "../Contact"

const MobileHome = () => {

    return <div>
        <Pagewrapper>
            <Events  showHeader={true}/>
            <footer class="rounded-lg shadow bg-gray-900 m-4">
                <Contact/>
                <hr class="my-6 border-gray-200 sm:mx-auto :border-gray-700 lg:my-8" />
                <div class="block text-sm text-gray-500 sm:text-center text-gray-400 pb-6">© 2024 <a href="https://.com/" class="hover:underline"></a>. All Rights Reserved.</div>
            </footer>
        </Pagewrapper>
    </div>
}

export default MobileHome