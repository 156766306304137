import Carousel from "./Carousel";
import Programs from "./sections/Programs";
import Faq from "./Faq";
import Announcements from "./sections/Announcements";
import Misc from "./sections/Misc";
import Events from "./sections/Events";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Review from "./sections/Review";


const Layout = () => {

    return (
        <div>
            <Navbar/>
            <Carousel />
            <Programs />
            <Events />
            <Announcements />
            <Misc />
            <Review />
            <Faq />
            <Footer />
        </div>
    )
}

export default Layout;