import { Card, CardHeader, CardBody, CardFooter, Typography, Button, } from "@material-tailwind/react";

const Cards = (props) => {

    return (
        <Card className="mt-6">
                <CardHeader color="blue-gray" className="relative">
                    <img
                    src={props.image}
                    alt="card-image"
                    />
                </CardHeader>
                <CardBody>
                    <Typography variant="h4" color="blue-gray" className="mb-2">
                        {props.header} 
                    </Typography>
                    <div className="text-slate-500 ">
                        {props.description}
                    </div>
                </CardBody>
                {
                    props.link 
                    &&
                    <CardFooter className="pt-0">
                        <a href={props.link}>
                            <Button size="sm" variant="text" className="flex items-center gap-2">
                                <div className="border-b-2 border-black">Learn More</div>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="h-4 w-4"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                />
                                </svg>
                            </Button>
                        </a>
                    </CardFooter>
                }
        </Card>
    )
}

export default Cards;