import Profile from '../../img/mastermin.jpeg'
import Pagewrapper from '../../component/pagewrapper/Pagewrapper';
import { ABOUTS } from '../../utils/Constant';

const AboutSection = () => {
    return <div>
            <div className='grid grid-cols-1 p-12 lg:grid-cols-2 lg:gap-6'>
                    <div className='w-8/12 m-auto'>
                        <img className='rounded-2xl' src={Profile} />
                    </div>
                    <div className='flex flex-col text-slate-700 text-md lg:text-xl mt-6 lg:mt-0 lg:leading-[2rem]'>
                        <div className=''>
                            <div className="text-2xl text-black  lg:text-5xl lg:leading-[3.5rem] font-black">
                                <div>MEET <span className='text-blue-800'>MASTER MIN</span></div>
                                <div className="h-2 bg-blue-800 w-16 my-2 lg:my-6 lg:w-24"></div>
                            </div>
                        </div>
                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                        {ABOUTS.map(about => <div className='relative border-l pl-8'>
                            <div class="absolute w-3 h-3 bg-gray-400 rounded-full mt-1.5 -left-1.5 border border-white"></div>
                            <div className='py-4'>
                                <div>{about}</div>
                            </div>
                        </div>)}
                        
                    </div>
            </div> 
    </div>

}

export default AboutSection